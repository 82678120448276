// import Script from "next/script";
import { Flex, Button, Text } from '@chakra-ui/react';
import Link from 'next/link';
import NavBar from '../components/Nav';

const Custom404 = () => {
  return (
    <>
      <NavBar />
      <Flex
        h="100vh"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text style={{ fontSize: 90, fontWeight: 'black' }}>404</Text>
        <Text style={{ fontSize: 24 }}>This page could not be found.</Text>
        <Link href={'/'} style={{ textDecoration: 'none' }}>
          <Button
            background="#cf0032"
            color="white"
            rounded={3}
            _hover={{
              color: 'white',
              opacity: 0.7,
            }}
            mt={20}
          >
            <Text fontSize={18} fontWeight="400">
              Return Home
            </Text>
          </Button>
        </Link>
      </Flex>
    </>
  );
};

export default Custom404;
